<template>
  <v-app>
    <v-main>
      <v-container class="mb-12">
        <v-sheet>
          <v-card flat max-height="300" min-height="300">
            <v-row>
              <v-col cols="12">
                <v-img
                  src="@/assets/discovery.jpg"
                  class="white--text align-end"
                  gradient="to top, rgba(0,0,0,.1), rgba(0.1,0,0,1)"
                  height="400"
                >
                  <v-row
                  >
                    <v-col cols="12">
                      <h3 class="text-center" >
                        Dicover new things about business
                      </h3>
                      <h3 class="text-center font-weight-thin mb-16 px-16">
                        Easily handle your business using <br />
                        Etousoft Softwares
                      </h3>
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-container>
      <v-container> </v-container>
      <!-- <v-container class="mt-12">
        <h2>Upcoming Products</h2>
        <v-row class="justify-center justify-md-start">
          <v-col cols="12" md="3">
            <v-card max-width="300" outlined>
              <v-img
                class="white--text align-end"
                height="200px"
                src="@/assets/strategy.jpg"
                gradient="to top, rgba(0,0,0,.1), rgba(0.1,0,0,1)"
              >
                <v-row  class="mb-16 align-center justify-center">
                  <v-card-title class="text-center">Comming soon</v-card-title>
                </v-row>
              </v-img>
              <v-card-subtitle class="pb-0 font-weight-black"
                >Stratgy PH</v-card-subtitle
              >

              <v-card-text class="text--primary">
                <div class="">Description of product</div>

                <div>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam,
                  nisi.
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card max-width="300" outlined>
              <v-img
                class="white--text align-end"
                height="200px"
                src="@/assets/strategy.jpg"
                gradient="to top, rgba(0,0,0,.1), rgba(0.1,0,0,1)"
              >
                <v-row  class="mb-16 align-center justify-center">
                  <v-card-title class="text-center">Comming soon</v-card-title>
                </v-row>
              </v-img>
              <v-card-subtitle class="pb-0 font-weight-black"
                >Stratgy PH</v-card-subtitle
              >

              <v-card-text class="text--primary">
                <div>Description of product</div>

                <div>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam,
                  nisi.
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card max-width="300" outlined>
              <v-img
                class="white--text align-end"
                height="200px"
                src="@/assets/strategy.jpg"
                gradient="to top, rgba(0,0,0,.1), rgba(0.1,0,0,1)"
              >
                 <v-row  class="mb-16 align-center justify-center">
                  <v-card-title class="text-center">Comming soon</v-card-title>
                </v-row>
              </v-img>
              <v-card-subtitle class="pb-0 font-weight-black"
                >Stratgy PH</v-card-subtitle
              >

              <v-card-text class="text--primary">
                <div>Description of product</div>

                <div>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam,
                  nisi.
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3">
            <v-card max-width="300" outlined>
              <v-img
                class="white--text align-end"
                height="200px"
                src="@/assets/strategy.jpg"
                gradient="to top, rgba(0,0,0,.1), rgba(0.1,0,0,1)"
              >
                 <v-row  class="mb-16 align-center justify-center">
                  <v-card-title class="text-center">Comming soon</v-card-title>
                </v-row>
              </v-img>
              <v-card-subtitle class="pb-0 font-weight-black"
                >Stratgy PH</v-card-subtitle
              >

              <v-card-text class="text--primary">
                <div>Description of product</div>

                <div>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam,
                  nisi.
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container> -->
      <!-- <v-container class="mt-12"></v-container> -->
    </v-main>
  </v-app>
</template>

<script>
export default {
};
</script>

<style scoped>
#btn {
  text-transform: none;
}
</style>

